@use '@angular/material' as mat;

:host {
    width: 100%;

    .group-panel {
        width: 100%;

        background-color: transparent;
        box-shadow: none;

        * {
            text-transform: uppercase;
            font-size: 1em;
            letter-spacing: 0.1px;
            font-weight: 600;
        }

        ::ng-deep .mat-expansion-panel-header {
            padding: 0 1em;
        }
    }
}

@mixin app-menu-group($theme) {
    $primary: map-get($theme, primary);

    .group-panel * {
        color: mat.get-contrast-color-from-palette($primary, 500);
    }

    .group-panel .mat-expansion-indicator:after {
        color: mat.get-contrast-color-from-palette($primary, 500);
    }
}

.group-panel.menu-items-panel {

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused,
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused,
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
        background: rgba(0, 0, 0, 0.04) !important;
    }
}

@media only screen and (max-width: 959px) {
    ::ng-deep div[dir="rtl"].parent-root {
        mat-expansion-panel.menu-items-panel .mat-expansion-panel-body {
            padding-right: 0;
        }
    }
}
