@use '@angular/material' as mat;

:host {
    display: block;
    background-color: whitesmoke;
    position: relative;
}

@mixin app-table-toolbar-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    app-table-toolbar[color="primary"] {
        background-color: mat.get-color-from-palette($primary);
    }

    app-table-toolbar[color="accent"] {
        background-color: mat.get-color-from-palette($accent);
    }

    app-table-toolbar[color="warn"] {
        background-color: mat.get-color-from-palette($warn);
    }
}
