@use '@angular/material' as mat;
@import 'color';

.app-input-icon {
    font-size: 0.9em;
}

.app-input {

}

button mat-spinner.app-btn-spinner {
    height: 25px;
    width: 25px;
    display: inline-block;
    color: #ffffff;
    margin: 5px 10px 5px 5px;

    path {
        stroke: mat.get-color-from-palette($nk3-accent);
    }
}

$width: 400px;

.app-select {
    max-width: $width;
    width: 100%;
    margin: 0.0em 0 0.8em 0;
    padding-bottom: 6px;
}

.app-select-error {
    margin-top: -1em;
    margin-bottom: 1em;
}

.app-date {
    max-width: $width;
    width: 100%;
}

.form-card {
    max-width: 1000px;
}


.form-note {
    position: relative;
    background-color: whitesmoke;
    padding: 1em 2em;
    border-left: 4px solid mat.get-color-from-palette($nk3-accent);

    &:before {
        background-color: mat.get-color-from-palette($nk3-accent);
        border-radius: 100%;
        color: #fff;
        content: "i";
        font-size: 14px;
        font-weight: 600;
        left: -12px;
        line-height: 20px;
        position: absolute;
        width: 20px;
        height: 20px;
        text-align: center;
        top: 14px;
        top: calc(50% - 10px);
    }
}
