@use '@angular/material' as mat;
@import '../../../../apps/klickdata/src/assets/style/color';

:host {
    display: block;
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 1.25em;

    ::ng-deep .mat-sidenav-content {
        overflow: visible;
    }

    .editor-container {
        margin-bottom: 5px;

        button {
            outline: none;
        }

        .editor-title {
            transition: color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
            margin-right: 1em;
        }

        .editor-title.focused {
            color: mat.get-color-from-palette($nk3-primary);
        }

        .editor-toolbar {
            opacity: 1;
            transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        .editor-toolbar.focused {
            opacity: 1;
        }
    }

    .text-input-underline {
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: rgba(0,0,0,0.17);

        .text-input-focusline {
            position: absolute;
            height: 2px;
            z-index: 1;
            top: -1px;
            width: 100%;
            transform-origin: top;
            opacity: 0;
            transition: opacity,background-color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        .text-input-focusline.focused {
            opacity: 1;
        }
    }

    .error-content {
        position: absolute;
        bottom: 0;
        font-size: 75%;
        opacity: 0;
        transform: translate(0, -100%);
        transition: opacity,transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &.ng-invalid.ng-dirty .error-content {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity,transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .tiny-container {
        min-height: 224px;
    }
}


@mixin app-text-editor($theme) {
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);

    .text-input-underline .text-input-focusline {
        background-color: mat.get-color-from-palette($primary);
    }

    app-text-editor.ng-invalid.ng-dirty .text-input-underline,
    app-text-editor.ng-invalid.ng-dirty .text-input-underline .text-input-focusline {
        background-color: mat.get-color-from-palette($warn);
    }

    app-text-editor .text-input-underline.disabled {
        $foreground: map-get($theme, foreground);
        $underline-color: mat.get-color-from-palette($foreground, divider, if(false, 0.7, 0.42));

        background-color: transparent !important;
        // @include mat.mat-control-disabled-underline($underline-color);
    }
}
