.app-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    height: 100vh;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 6em;
    height: 6em;
}
.loader {
    margin: 20px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(120,151,207, 0.2);
    border-right: 1.1em solid rgba(120,151,207, 0.2);
    border-bottom: 1.1em solid rgba(120,151,207, 0.2);
    border-left: 1.1em solid #7897cf;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
