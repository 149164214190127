@use '@angular/material' as mat;

@mixin app-stepper-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .step-badge {
        background-color: mat.get-color-from-palette($primary);
    }
}
