@import 'assets/icons/styles';
@import 'assets/style/color';
@import 'assets/style/md-config';
@import 'assets/style/md-tabs';
@import 'assets/style/layout';
@import 'assets/style/typografy';
@import 'assets/style/loading';
@import 'assets/style/form';
@import 'assets/style/alert';
@import 'assets/style/backdrop';
@import 'assets/style/icon';
@import 'assets/style/ngx-datatable';
@import 'assets/style/mat-changes';
@import 'assets/style/ngx-charts';

@import '@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '@swimlane/ngx-datatable/assets/icons.css';

@import 'ngx-sharebuttons/themes/circles/circles-dark-theme';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

$flag-icon-css-path: 'flag-icon-css/flags' !default;

// custom flags
.flag-icon-ta {
    background-image: url(assets/flags/ta.svg);
}

.flag-icon-arl {
    background-image: url(assets/flags/arl.svg); // Flag of the Arab League
}

@import 'flag-icon-css/sass/flag-icons';
@import 'pikaday/scss/pikaday';

.half-stroke {
    -webkit-text-stroke: 0.5px;
}

@media only screen and (min-width: 1920px) {
    .mat-bottom-sheet-container-xlarge {
        min-width: 50vw !important;
    }
}
