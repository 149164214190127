@use '@angular/material' as mat;
@import 'color';

// Define a custom typography config
// https://material.angular.io/guide/typography
$custom-typography: mat.define-typography-config(
    $font-family: '"Source Sans Pro", sans-serif !important',
    $headline:      mat.define-typography-level(45px, 50px, 300), // h1
    $title:         mat.define-typography-level(34px, 40px, 500), // h2
    $subheading-2:  mat.define-typography-level(24px, 32px, 400), // h3
    $subheading-1:  mat.define-typography-level(20px, 28px, 400), // h4
    // $body-2:        mat-typography-level(14px, 24px, 500), // bolder body
    // $body-1:        mat-typography-level(14px, 20px, 400), // base body
    // $caption:       mat-typography-level(12px, 20px, 400), // smaller body and hint text
    // $button:        mat-typography-level(14px, 14px, 500), // buttons and anchors

    $input:         mat.define-typography-level(inherit, 1.125, 400) // Line-height must be unit-less fraction
);


body, div, p, h1, h2, h3, h4, span, input, textarea, mat-cell, mat-table {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-display: auto;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, p, div, .title {
    color: rgba(0,0,0,0.87);
}

h1.x-large {
    font-size: 112px;
    font-weight: 300;
}

h1.large {
    font-size: 56px;
    font-weight: 300;
}

h1 {
    font-size: 45px;
    font-weight: 300;
}

h2 {
    font-size: 34px;
    font-weight: 400;
}

h3 {
    font-size: 24px;
    font-weight: 400;
}

h4 {
    font-size: 20px;
    font-weight: 400;
}

// For use in cards
.title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

// For use in cards
.title-icon {
    font-size: 30px;
}

.color-primary {
    color: mat.get-color-from-palette($nk3-primary);
}

.color-accent {
    color: mat.get-color-from-palette($nk3-accent);
}

.color-warn {
    color: mat.get-color-from-palette($nk3-warn);
}

.color-success {
    color: #3c763d;
}

.color-red {
    color: #e44a66;
}

.color-light {
    color: whitesmoke;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.app-caption {
    @include mat.typography-level($custom-typography, caption);
}

.app-icon {
    color: rgba(0,0,0,.54);
}

@mixin mat-typography-theme($theme) {
    $foreground: map-get($theme, foreground);

    .app-caption {
        color: mat.get-color-from-palette($foreground, hint-text);
    }
}
