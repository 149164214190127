@use '@angular/material' as mat;

:host {
    width: 100%;

    .resources-list.cdk-drop-list-dragging .item:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .has-img {
        color: #bfd8d0;
    }

    .ai-uploaded {
        color: #93cbd1;
    }

    app-kd-logo-loading {
        width: 40px;
        height: 30px;
        // margin-right: -10px;
    }

    .item-desc p {
        margin: 0;
        overflow: hidden;
        // width: 800px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .question-reorder {
        cursor: move;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item-desc mat-icon {
        font-size: 14px;
        height: 14px;
        width: 14px;
        color: rgba(0, 0, 0, 0.44);
    }

    .item-desc span span {
        margin-top: -3px;
        margin-left: 7px;
        color: rgba(0, 0, 0, 0.44);
    }

    // .mat-expansion-panel-header p.color-warn {
    //     max-height: 40px;
    //     width: 173px;
    // }

    .mat-expansion-panel-header p.color-warn {
        max-height: 20px;
        width: auto;
        margin: 0;
        overflow: hidden;

        @media (max-width: 599px) {
            overflow: visible;
        }
    }
}

::ng-deep .cdk-drag-preview {
    .question-title-index {
        background-color: #93cbd1;
    }

    .item-desc p {
        margin: 0;
    }
}

::ng-deep .qs-form-build {
    .mat-form-field.mat-form-field-invalid .mat-form-field-label,
    .mat-error,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
        color: #ff9961 !important;
    }

    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
        background-color: #ff9961 !important;
    }
}

::ng-deep {
    app-language-selector.icon-resource-builder .flag-icon {
        line-height: 1.2em;
    }

    app-survey-settings,
    app-admin-test-settings-form,
    app-course-plan-settings,
    app-auth-tool-settings,
    app-course-create {
        app-language-selector.icon-resource-builder .flag-icon {
            margin-bottom: 5px;
        }
    }

    .home-cp,
    .home-test-create,
    .home-scorm-create,
    .home-survey-create {
        .question-title-index {
            background-color: #bfd8d0;
        }

        .item-desc p {
            margin: 0;
        }

        app-resource-items mat-accordion {
            max-width: 100% !important;
        }
    }

    .wok-qus {
        background-color: #93cbd1 !important;
    }

    app-create-course-plan-simple
        .home-cp-first-step.home-cp-v-stepper
        app-resource-builder-form
        app-resource-items
        mat-accordion {
        min-width: 100%;
    }

    .res-item .resources-list .mat-expansion-panel-header-description button .mat-button-wrapper {
        justify-content: center;
    }

    @media (max-width: 959px) {
        mat-expansion-panel.item.no-shadow {
            mat-expansion-panel-header.question-header {
                height: auto !important;
                padding: 0 5px;
                min-height: 100px;

                .mat-content {
                    flex-direction: column !important;
                    gap: 5px;
                    padding: 4px;

                    mat-panel-title {
                        align-items: flex-start !important;

                        .question-title-index {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            min-width: 20px;
                            font-size: 14px;
                            text-align: center;
                            line-height: 13px;
                            margin-right: 6px;
                            padding-top: 3px;
                        }
                    }

                    mat-panel-description.btns-desc {
                        width: 100%;
                        align-items: center !important;
                        justify-content: flex-end !important;
                        gap: 5px;

                        .mr-1,
                        .ml-1 {
                            margin: 0;
                        }
                    }
                }

                .mat-expansion-indicator {
                    width: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

.question-title-index {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    min-width: 35px;
    font-size: 1.5em;
    text-align: center;
    line-height: 24px;
    margin-right: 1em;
    padding-top: 3px;
}

::ng-deep {
    mat-slide-toggle.mandatory-toggle.mat-checked {
        .mat-slide-toggle-thumb {
            background-color: #d9e7e3;
        }

        .mat-slide-toggle-bar {
            background-color: rgba(217, 231, 227, 0.4);
        }
    }

    mat-slide-toggle.mandatory-toggle {
        .mat-slide-toggle-thumb {
            height: 16px;
            width: 16px;
            border-radius: 50%;
        }

        .mat-slide-toggle-bar {
            position: relative;
            width: 30px;
            height: 11px;
            flex-shrink: 0;
            border-radius: 8px;
        }
    }
}

::ng-deep div[dir='rtl'].parent-root {
    app-admin div.res-item .question-title-index {
        margin-left: 1em;
        margin-right: 0 !important;
    }

    .item-desc span span {
        margin-right: 7px;
        margin-left: 0 !important;
    }
}

.item.disabled {
    p {
        text-decoration: line-through;
        color: rgba(0, 0, 0, 0.5);
    }

    .question-title-index {
        background-color: rgba(0, 0, 0, 0.25);
    }
}

@mixin resource-builder-items-theme($theme) {
    $primary: map-get($theme, primary);

    .question-title-index {
        background-color: mat.get-color-from-palette($primary);
    }
}
