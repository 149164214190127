@use '@angular/material' as mat;

:host {
    .templates-toolbar {
        margin: 0;
        padding: 0;
        height: 48px;

        color: #000;

        .btn {
            color: #000;
        }
    }

    .templates {
        margin: 1em 0.5em;

        .template-preview {
            width: 100%;
            height: 180px;
            overflow: hidden;

            .template-shadow {
                @include mat.elevation(4);
                width: 90%;
                height: 100%;
                margin-top: 1em;

                .template-preview-contents {
                    transform: scale(0.5);
                    width: 200%;
                    height: 200%;
                    transform-origin: 0 0;
                    padding: 1em;
                    text-align: left;
                    background-color: white;
                }
            }
        }

        .title {
            word-break: break-word;
        }
    }
}

@mixin app-editor-template-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .templates .template-preview [color='primary'] {
        background-color: mat.get-color-from-palette($primary);
    }

    .templates .template-preview,
    .templates .template-preview [color='accent'] {
        background-color: mat.get-color-from-palette($accent);
    }

    .templates .template-preview [color='warn'] {
        background-color: mat.get-color-from-palette($warn);
    }
}

::ng-deep div[dir='rtl'].parent-root {
    .templates {
        .template-preview {
            .template-shadow {
                .template-preview-contents {
                    transform: scale(-0.5) !important;
                    text-align: right !important;
                }
            }
        }
    }
}
