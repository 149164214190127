
.alert {
    width: 100%;
    border: 1px solid #b3b3b3;
    background-color: #f0f0f0;
    padding: 0 20px;
    box-sizing: border-box;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;

    a, p, .title, .alert-heading {
        color: #8a6d3b;
    }
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;

    a, p, .title, .alert-heading {
        color: #a94442;
    }
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;

    a, p, .title, .alert-heading {
        color: #3c763d;
    }
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;

    a, p, .title, .alert-heading {
        color: #31708f;
    }
}
