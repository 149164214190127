.mat-row-link {
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}

.mat-tab-label-content {
    display: flex;
    align-items: center;
}

.mat-toolbar.compact {
    font-size: 14px;
}

.mat-tab-label-content {
    text-transform: capitalize;
}

.mat-button {
    .mat-button-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .mat-icon {
            font-size: 16px;
            height: 18px;
            width: 18px;
            margin-right: 8px;
        }
    }
}

// div[dir="rtl"].parent-root {
//     .mat-button .mat-button-wrapper .mat-icon {
//         margin-right: inherit !important;
//         margin-left: 8px;
//     }
// }
