/* For use in src/lib/core/theming/_palette.scss */
@use '@angular/material' as mat;
@include mat.core();

$mat-light-magenta: (
    50  : #f9dbe1,
    100 : #f2b7c2,
    200 : #ec93a4,
    300 : #e76f86,
    400 : #e44a66,
    500 : #e44a66,
    600 : #e44a66,
    700 : #e44a66,
    800 : #e44a66,
    900 : #e44a66,
    A100 : #f9dbe1,
    A200 : #f2b7c2,
    A400 : #ec93a4,
    A700 : #e76f86,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-deep-blue: (
    50  : #d9dde0,
    100 : #b2bac1,
    200 : #8c98a2,
    300 : #667683,
    400 : #3e5365,
    500 : #3e5365,
    600 : #3e5365,
    700 : #3e5365,
    800 : #3e5365,
    900 : #3e5365,
    A100 : #d8dde0,
    A200 : #b2bac1,
    A400 : #8b98a3,
    A700 : #657584,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-happy-orange: (
    50  : #feeae0,
    100 : #fdd6c1,
    200 : #fdc1a2,
    300 : #fcad84,
    400 : #ff9961,
    500 : #ff9961,
    600 : #ff9961,
    700 : #ff9961,
    800 : #ff9961,
    900 : #ff9961,
    A100 : #ffebdf,
    A200 : #ffc2a0,
    A400 : #ffad81,
    A700 : #ff9961,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-spring-green: (
    50 : #f2f7f6,
    100 : #e6efec,
    200 : #d9e8e3,
    300 : #cce0d9,
    400 : #bfd8d0,
    500 : #bfd8d0,
    600 : #bfd8d0,
    700 : #bfd8d0,
    800 : #bfd8d0,
    900 : #bfd8d0,
    A100 : #f2f7f6,
    A200 : #e5efec,
    A400 : #d8e8e3,
    A700 : #cce0da,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-light-grey: (
    50 : #fdfdfd,
    100 : #fbfbfb,
    200 : #f8f8f8,
    300 : #f6f6f6,
    400 : #f4f4f4,
    500 : #f4f4f4,
    600 : #f4f4f4,
    700 : #f4f4f4,
    800 : #f4f4f4,
    900 : #f4f4f4,
    A100 : #fdfdfd,
    A200 : #fbfbfb,
    A400 : #f8f8f8,
    A700 : #f6f6f6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$mat-calm-cyan: (
    50   : #eaf5f6,
    100  : #d5eaec,
    200  : #c0e0e3,
    300  : #abd5da,
    400  : #93cbd1,
    500  : #bfd8d0,
    600  : #93cbd1,
    700  : #93cbd1,
    800  : #93cbd1,
    900  : #93cbd1,
    A100 : #e9f5f6,
    A200 : #d4eaee,
    A400 : #bee0e3,
    A700 : #a9d5da,
    contrast: (
        50   : #000000,
        100  : #000000,
        200  : #000000,
        300  : #000000,
        400  : #000000,
        500  : #000000,
        600  : #000000,
        700  : #000000,
        800  : #000000,
        900  : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-success: (
    50   : #ffffff,
    100  : #ffffff,
    200  : #ffffff,
    300  : #ffffff,
    400  : #ffffff,
    500  : #dff0d8,
    600  : #dff0d8,
    700  : #dff0d8,
    800  : #dff0d8,
    900  : #dff0d8,
    A100 : #dff0d8,
    A200 : #dff0d8,
    A400 : #dff0d8,
    A700 : #dff0d8,
    contrast: (
        50   : #3c763d,
        100  : #3c763d,
        200  : #3c763d,
        300  : #3c763d,
        400  : #3c763d,
        500  : #3c763d,
        600  : #3c763d,
        700  : #3c763d,
        800  : #3c763d,
        900  : #3c763d,
        A100 : #3c763d,
        A200 : #3c763d,
        A400 : #3c763d,
        A700 : #3c763d,
    )
);

$nk3-primary:                mat.define-palette($mat-deep-blue);
$nk3-primary-light:          mat.define-palette($mat-deep-blue, 50);
$nk3-accent:                 mat.define-palette($mat-calm-cyan, 300);
$nk3-warn:                   mat.define-palette($mat-happy-orange);
$nk3-success:                mat.define-palette($mat-success);
$nk3-error:                  mat.define-palette($mat-light-magenta);

$nk3-admin-primary:          mat.define-palette($mat-calm-cyan);
$nk3-admin-primary-light:    mat.define-palette($mat-calm-cyan, 50);
$nk3-admin-accent:           mat.define-palette($mat-spring-green, 200);
$nk3-admin-error:            mat.define-palette($mat-light-magenta);

$nk3-master-primary:         mat.define-palette($mat-happy-orange);
$nk3-master-primary-light:   mat.define-palette($mat-happy-orange, 50);
$nk3-master-accent:          mat.define-palette($mat-calm-cyan, 300);
$nk3-master-error:           mat.define-palette($mat-light-magenta);
