@use '@angular/material' as mat;

:host {
    @media (max-width: 959px) {
        width: 100%;
    }
    @media (min-width: 959px) {
        display: flex!important;
    }

}

:host > button {
    flex: 1 1 auto;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.1px;
    font-weight: 600;
    min-width:50px;
    text-align: left;
}

:host > button.menu-item ::ng-deep .mat-button-wrapper {
    position: relative;

    &:after {
        content: " ";
        height: 0px;
        position: absolute;
        left: -6px;
        bottom: -4px;
        right: -6px;

        transform: scale3d(0,0,0);
        opacity: 0;
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
}

:host > button.menu-item.active ::ng-deep .mat-button-wrapper:after {
    transform: scale3d(1,1,1);
    opacity: 1;
}

@mixin app-menu-item($theme) {
    $primary: map-get($theme, primary);

    button.menu-item {
        color: mat.get-contrast-color-from-palette($primary, 500);
    }

    button.menu-item .mat-button-wrapper:after {
        background-color: mat.get-contrast-color-from-palette($primary, 500);
    }
}
