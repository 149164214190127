@charset "UTF-8";

@font-face {
  font-family: 'klickdata';
  src:  url('../icons/klickdata-medium.eot');
  src:  url('../icons/klickdata-medium.eot?iefix') format('embedded-opentype'),
    url('../icons/klickdata-medium.ttf') format('truetype'),
    url('../icons/klickdata-medium.woff') format('woff'),
    url('../icons/klickdata-medium.svg#klickdata-medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "klickdata";
  src:url("../icons/klickdata-light.eot");
  src:url("../icons/klickdata-light.eot?#iefix") format("embedded-opentype"),
    url("../icons/klickdata-light.woff") format("woff"),
    url("../icons/klickdata-light.ttf") format("truetype"),
    url("../icons/klickdata-light.svg#klickdata-light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "klickdata";
  src:url("../icons/klickdata-bold.eot");
  src:url("../icons/klickdata-bold.eot?#iefix") format("embedded-opentype"),
    url("../icons/klickdata-bold.woff") format("woff"),
    url("../icons/klickdata-bold.ttf") format("truetype"),
    url("../icons/klickdata-bold.svg#klickdata-bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

[data-icon]:before {
  font-family: "klickdata" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="kd-icon-"]:before,
[class*=" kd-icon-"]:before {
  font-family: "klickdata" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.light[class^="kd-icon-"]:before,
.light[class*=" kd-icon-"]:before {
  font-weight: 300 !important;
}
.bold[class^="kd-icon-"]:before,
.bold[class*=" kd-icon-"]:before {
  font-weight: 700 !important;
}


.kd-icon-booking:before {
  content: "\62";
}
.kd-icon-user:before {
  content: "\63";
}
.kd-icon-test:before {
  content: "\64";
}
.kd-icon-task:before {
  content: "\65";
}
.kd-icon-document:before {
  content: "\66";
}
.kd-icon-exam:before {
  content: "\67";
}
.kd-icon-focus:before {
  content: "\68";
}
.kd-icon-group:before {
  content: "\69";
}
.kd-icon-knowledge:before {
  content: "\6a";
}
.kd-icon-message:before {
  content: "\6b";
}
.kd-icon-player:before {
  content: "\6c";
}
.kd-icon-presentation:before {
  content: "\6d";
}
.kd-icon-search-user:before {
  content: "\6e";
}
.kd-icon-settings:before {
  content: "\6f";
}
.kd-icon-statistics:before {
  content: "\70";
}
.kd-icon-survey:before {
  content: "\71";
}

.kd-icon-12, .mat-icon.kd-icon-12 {
    font-size: 12px;
    line-height: 1;
    height: 12px;
    width: 12px;
}

/* @deprecated Wrong size */
.kd-icon-16, .mat-icon.kd-icon-16 {
    font-size: 16px;
    line-height: 1;
    height: 16px;
    width: 16px;
}

.kd-icon-24, .mat-icon.kd-icon-24 {
    font-size: 24px;
    line-height: 1;
    height: 24px;
    width: 24px;
}

.kd-icon-36, .mat-icon.kd-icon-36 {
    font-size: 36px;
    line-height: 1;
    height: 36px;
    width: 36px;
}

/* @deprecated Wrong size */
.kd-icon-42, .mat-icon.kd-icon-42 {
    font-size: 42px;
    line-height: 1;
    height: 42px;
    width: 42px;
}

.kd-icon-48, .mat-icon.kd-icon-48 {
    font-size: 48px;
    line-height: 1;
    height: 48px;
    width: 48px;
}

.kd-icon-96, .mat-icon.kd-icon-96 {
    font-size: 96px;
    line-height: 1;
    height: 96px;
    width: 96px;
}
