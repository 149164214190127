@use '@angular/material' as mat;

app-pikaday {
    margin-top: 1em;

    .container {
        width: 100%;

        .date-container {
            .is-picked button.pika-button.pika-day, .is-selected button.pika-button.pika-day {
                border-radius: 0;
            }

            .pika-button:hover {
                border-radius: 0;
            }

            .pika-single {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                border: none;

                .pika-lendar {
                    float: none;
                    flex: 0 0 auto;

                    .pika-next, .pika-prev {
                        visibility: hidden;
                    }
                }
            }
        }

        .nav-btn {
            margin: 1em;
        }
    }
}

@mixin app-pikaday($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .is-picked .pika-button.pika-day {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-contrast-color-from-palette($primary, 500);
    }

    .pika-button:hover {
        background-color: mat.get-color-from-palette($accent);
        color: mat.get-contrast-color-from-palette($accent, 500);
    }
}

